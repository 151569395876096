<script lang="ts">
    import { onMount } from "svelte";
    import type { MapNode } from "../models/MapNode";
    import {
        mapNodes,
        currentNode,
        username,
        positionVisited,
    } from "../services/stores";

    onMount(() => {
        view.scrollTo({
            top: (1210 - 800) / 2,
            left: (1210 - 800) / 2,
        });

        let pos = { top: 0, left: 0, x: 0, y: 0 };

        const mouseDownHandler = function (e) {
            pos = {
                // The current scroll
                left: view.scrollLeft,
                top: view.scrollTop,
                // Get the current mouse position
                x: e.clientX,
                y: e.clientY,
            };
            view.style.cursor = "grabbing";
            view.style.userSelect = "none";

            view.addEventListener("mousemove", mouseMoveHandler);
            view.addEventListener("mouseup", mouseUpHandler);
        };

        const mouseMoveHandler = function (e) {
            // How far the mouse has been moved
            const dx = e.clientX - pos.x;
            const dy = e.clientY - pos.y;

            // Scroll the element
            view.scrollTop = pos.top - dy;
            view.scrollLeft = pos.left - dx;
        };

        const mouseUpHandler = function () {
            view.removeEventListener("mousemove", mouseMoveHandler);
            view.removeEventListener("mouseup", mouseUpHandler);

            view.style.cursor = "grab";
            view.style.removeProperty("user-select");
        };

        view.addEventListener("mousedown", mouseDownHandler);
    });

    let view: HTMLDivElement;
    let others: Array<[string, number, number]> = [];

    const portal: MapNode = {
        coord: [0, 0],
        visitedBy: [$username],
        mark: false
    };

    currentNode.set(portal);
    mapNodes.set([$currentNode]);

    positionVisited.subscribe((params) => {
        if (!params || !params[0] || params[1] === '') return;

        const [otherNode, name] = params;

        const existingNode = $mapNodes.find((mp) => {
            return (
                otherNode.coord[0] === mp.coord[0] &&
                otherNode.coord[1] === mp.coord[1]
            );
        });

        function onlyUnique(value, index, self) {
            return self.indexOf(value) === index;
        }

        function updateOthers() {
            const trackOther = others.find(o => o[0] === name);
            if(trackOther) {
                trackOther[1] = otherNode.coord[0];
                trackOther[2] = otherNode.coord[1];
                others = [...others];
            } else {
                others = [...others, [name, otherNode.coord[0], otherNode.coord[1]]];
            }
        }

        function scrollIntoView() {
            setTimeout(() => {
                const el = document.getElementsByClassName('current')[0];
                el.scrollIntoView({behavior: 'smooth', inline: 'center', block: 'center'});
            }, 100);
        }

        if (existingNode) {
            existingNode.visitedBy = [
                ...existingNode.visitedBy,
                ...otherNode.visitedBy,
            ].filter(onlyUnique);
            $mapNodes = [...$mapNodes];
            if (name === $username) {
                $currentNode = existingNode;
                scrollIntoView();
            } else {
                updateOthers();
            }
            return;
        }

        if(name !== $username) {
            updateOthers();
        }

        $mapNodes = [...$mapNodes, otherNode];
        if (name === $username) {
            $currentNode = otherNode;
            scrollIntoView();
        }
    });

    const isCurrentNode = (node: MapNode) => {
        console.log($mapNodes);
        console.log(node);
        console.log($currentNode);
        return (
            $currentNode.coord[0] === node.coord[0] &&
            $currentNode.coord[1] === node.coord[1]
        );
    };

    const getIndexes = (x: number, y: number) => {
        return others.map((o, idx) => o[1] === x && o[2] === y ? idx : null).filter(o => o != null);
    };

    const store = {};
    const handleNodeClick = (mapNode: MapNode) => {
        return function(e: MouseEvent) {
            if(store['has'] && store[e.x+'-'+e.y]) {
                mapNode.mark = !mapNode.mark;
                $mapNodes = [...$mapNodes];
                for (var field in store) {
                    delete store[field];
                }
            } else if(store['has']) {
                for (var field in store) {
                    delete store[field];
                }
            } else {
                store[e.x+'-'+e.y] = true;
                store['has'] = true;
            }
        };
    };
</script>
<div class="row">
    <div class="row"><span class="marker-me">{$username}</span></div>
    {#each others as other, i}
        <div class="row"><span class="marker-other-{i}">{other[0]}</span></div>
    {/each}
</div>
<div class="view" bind:this={view}>
    <div class="nodes">
        {#each $mapNodes as mapNode}
            <!-- svelte-ignore a11y-click-events-have-key-events -->
            <div
                on:click={handleNodeClick(mapNode)}
                class="map-node x-{mapNode.coord[0]} y-{mapNode
                    .coord[1]} by-me-{mapNode.visitedBy.indexOf($username) >=
                    0} {isCurrentNode(mapNode) ? 'current' : ''} {getIndexes(...mapNode.coord).map(n => `other-${n}`).join(' ')}"
            >
                {`${mapNode.coord[0]}, ${mapNode.coord[1]}`}
                {#if mapNode.mark}
                  <span class="marked" />
                {/if}
            </div>
        {/each}
    </div>
</div>

<style lang="scss">
    $nodeSize: 50px;
    $nodeGap: 5px;
    $cornerSize: calc($nodeSize / 5);
    $primaryColor: rgb(155, 28, 155);
    $secondaryColor: rgb(207, 56, 86);
    $trinaryColor: rgb(92, 92, 255);

    .map-node {
        height: $nodeSize;
        width: $nodeSize;
        color: black;
        background-color: white;
        border: 1px solid black;
        position: absolute;
        font-size: 16px;
        text-align: center;



        &.other-0 {
            border: 3px solid $secondaryColor;
            margin: -2px;

            &.other-1 {
                border-bottom: 3px solid $trinaryColor;
                border-right: 3px solid $trinaryColor;
                margin: -2px;
            }
        }

        &.other-1 {
            border: 3px solid $trinaryColor;
            margin: -2px;

            &.other-0 {
                border-bottom: 3px solid $secondaryColor;
                border-right: 3px solid $secondaryColor;
                margin: -2px;
            }
        }

        &.current {
            border: 3px solid $primaryColor;
            margin: -2px;

            &.other-0 {
                border-right: 3px solid $secondaryColor;
                margin: -2px;
            }

            &.other-1 {
                border-bottom: 3px solid $trinaryColor;
                margin: -2px;
            }
        }
    }

    .by-me-true {
        background-color: aquamarine;
    }

    .marked {
        height: 20px;
        width: 20px;
        background-color: green;
        display: flex;
        border-radius: 10px;
        margin: auto;
    }

    .marker-other-0 {
        &::before {
            content: '';
            display: inline-block;
            background-color: $secondaryColor;
            height: 12px;
            width: 12px;
            margin-right: 2px;
        }
    }

    .marker-other-1 {
        &::before {
            content: '';
            display: inline-block;
            background-color: $trinaryColor;
            height: 12px;
            width: 12px;
            margin-right: 2px;
        }
    }

    .marker-me {
        &::before {
            content: '';
            display: inline-block;
            background-color: $primaryColor;
            height: 12px;
            width: 12px;
            margin-right: 2px;
        }
    }

    .view {
        width: 800px;
        height: 800px;
        overflow: scroll;
        cursor: grab;
    }

    .nodes {
        position: relative;
        width: ($nodeSize + $nodeGap) * 22;
        height: ($nodeSize + $nodeGap) * 22;
        @for $i from 0 through 60 {
            .x-#{$i - 30} {
                left: calc(
                    50% + (($i - 30) * ($nodeSize + $nodeGap)) - ($nodeSize / 2)
                );
            }
        }

        @for $i from 0 through 60 {
            .y-#{$i - 30} {
                top: calc(
                    50% +
                        (($i - 30) * (0px - ($nodeSize + $nodeGap))) -
                        ($nodeSize / 2)
                );
            }
        }
    }

    .row {
        display: flex;
        flex-direction: row;
        margin: 10px;
    }
</style>
