<script lang="ts">
    import Goals from "./components/Goals.svelte";
    import Map from "./components/Map.svelte";
    import Name from "./components/Name.svelte";
    import { username } from "./services/stores";
</script>

<main>
    {#if $username === ''}
        <Name />
    {:else}
    <div>
        <Map />
    </div>
    <div class="right-panel">
        <div class="area">
            <Goals />
        </div>
    </div>
    {/if}
</main>

<style lang="scss">
    main {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    :global(body) {
        background-color: black;
        color: lightgrey;
    }

    @media (min-width: 640px) {
        main {
            max-width: none;
        }
    }

    .right-panel {
        position: absolute;
        right: 0;

        .area {
            position: relative;
            display: flex;
            flex-direction: column;
        }
    }
</style>
