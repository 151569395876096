<script lang="ts">
    import { goals, positionVisited } from "../services/stores";

    let trackedGoals: Record<string, Array<Record<string, string>>> = {};
    let names: Record<string, string> = {};

    goals.subscribe((g) => {
        trackedGoals = g;
    });

    positionVisited.subscribe((p) => {
        const name = p[1];
        const uuid = p[2];
        if (!names[uuid]) {
            names[uuid] = name;
        }
    });
</script>

{#each Object.keys(trackedGoals) as uuid}
    <div>{names[uuid] ?? uuid}</div>
    <div class="row">
        {#each trackedGoals[uuid] as goal}
            <div class="column">
                <div class="row">
                    <div>
                        <img
                            class="item-location"
                            src={goal.secondary_icon.replace(
                                "the_vault:textures/gui/scavenger",
                                "images"
                            )}
                            alt={goal.secondary_icon}
                        />
                    </div>
                    <div>
                        <img
                            class="item"
                            src="images/{goal.item.replace(
                                'scavenger_',
                                ''
                            )}.png"
                            alt={goal.item}
                        />
                    </div>
                </div>
                <div class="row center numbers">
                    {goal.current} / {goal.total}
                </div>
                <div class="row center">
                    {goal.item.replace('scavenger_', '').replaceAll('_', ' ')}
                </div>
            </div>
        {/each}
    </div>
{/each}

<style lang="scss">
    .row {
        display: flex;
        flex-direction: row;
    }

    .column {
        display: flex;
        flex-direction: column;
        margin: 8px;
        max-width: 90px;

        .item-location {
            image-rendering: pixelated;
            width: 30px;
        }

        .item {
            image-rendering: pixelated;
            width: 60px;
        }

        .numbers {
            font-size: 20px;
            color: green;
        }
    }

    .center {
        justify-content: center;
    }

    
</style>
