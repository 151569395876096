import { readable, writable } from 'svelte/store';
import { socket } from './socket';
export const mapNodes = writable([]);
export const currentNode = writable();
export const nodeVisited = readable([{ coord: [0, 0], visitedBy: [], mark: false }, ''], set => {
    socket.on('nodeVisit', (name, visit) => {
        set([visit, name]);
    });
});
export const positionVisited = readable([{ coord: [0, 0], visitedBy: [], mark: false }, '', ''], set => {
    socket.on('playerPosition', (name, visit, uuid) => {
        set([{ coord: [visit.x, visit.z], visitedBy: [name], mark: false }, name, uuid]);
    });
});
export const username = writable('', set => {
    const name = getCookie('name');
    if (name && name !== '') {
        set(name);
    }
});
export const goals = readable({
    '': [],
}, set => {
    socket.on('goals', g => {
        set(g);
    });
});
username.subscribe((value) => {
    if (value && value !== '') {
        document.cookie = `name=${value}`;
    }
});
function getCookie(cname) {
    const name = cname + '=';
    const decodedCookie = decodeURIComponent(document.cookie);
    const ca = decodedCookie.split(';');
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
        }
    }
    return '';
}
