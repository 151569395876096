<script lang="ts">
    import { username } from "../services/stores";

    let name = '';

    const submitName = () => {
        $username = name;
    };

    function handleEnter(e: KeyboardEvent) {
        if (!name) return;

        if (e.key === "Enter") {
            submitName();
        }
    }
</script>

<div class="row">
    <label for="username">Name</label>
    <input name="username" id="username" type="text" bind:value={name} on:keyup={handleEnter} />
    <button type="button" on:click={submitName}>Go</button>
</div>

<style lang="scss">
    .row {
        display: flex;
        flex-direction: row;
    }
</style>